<script setup>
    import { ref, computed, h } from 'vue'; 
    import { FormulaObject, getDefaultOperations } from 'o365.modules.formula.js';

    const props = defineProps(['modelValue', 'column', 'customOperations']);
    const emit = defineEmits(['update:modelValue']);

    function ArgumentInput(props, context) {
        switch (props.argument.constructor.name) {
            case 'InputArgument':
                return h('input', {
                    type: props.argument.inputType,
                    value: props.modelValue,
                    class: 'form-control',
                    'onInput': e => context.emit('update:modelValue', e.target.value),
                });
            case 'SelectArgument':
                return h('select', {
                    value: props.modelValue,
                    class:'form-select',
                    'onInput': e => context.emit('update:modelValue', e.target.value),
                }, props.argument.selectOptions.map(option => h('option', {
                    value: option.value
                }, option.label)))
            default:
                return h('span', { class: 'text-danger' }, 'Invalid argument');
        }
    };
    ArgumentInput.props = ['modelValue', 'argument'];
    ArgumentInput.emits = ['update:modelValue'];

    const formulaString = ref(null);

    const operations = computed(() => {
        const defaultOperations = getDefaultOperations(props.column.type);
        return defaultOperations;
    });

    const formulaObject = ref(new FormulaObject({
        operation: operations.value[0],
        value: [],
        currentField: props.column.name
    }));

    const editMode = ref(false);
    function enterEditMode() {
        editMode.value = true;
    };
    function exitEditMode() {
        formulaObject.value.fillInCurrentField();
        invalidFormula.value = !formulaObject.value.isValid();
        if (!invalidFormula.value) {
            formulaString.value = formulaObject.value.toString();
            props.modelValue[props.column.name] = formulaObject.value;
        } else {
            formulaString.value = null;
        }
        editMode.value = false;
    };

    const invalidFormula = ref(false);

</script>

<template>
    <div class="row my-2 g-1 mx-2">
        <template v-if="editMode">
            <div class="col-3">
                <select class="form-select" v-model="formulaObject.operation">
                    <option v-for="(item, index) in operations" :key="index" :value="item">{{item.label}}</option>
                </select>
            </div>
            <template v-for="(argument, index) in formulaObject.operation.arguments">
                <div :class="'col-'+argument.col" v-if="argument.render">
                    <ArgumentInput v-model="formulaObject.value[index]" :argument="argument" />
                </div>
            </template>
            <div class="col-1">
                <button class="btn btn-link btn-sm" title="Done" @click="exitEditMode">
                    <i class="fa-solid fa-check"></i>
                </button>
            </div>
        </template>

        <template v-else>
            <div class="col-11 ps-1">
                <code :class="{'is-invalid': invalidFormula}">{{formulaString}}</code>
                <div class="invalid-feedback">Invalid formula</div>
            </div>
            <div class="col-1">
                <button class="btn btn-link btn-sm" title="Edit" @click="enterEditMode">
                    <i class="bi bi-wrench-adjustable"></i>
                </button>
            </div>
        </template>
    </div>
</template>